// @ts-nocheck
/* eslint-enable */
import isEmpty from 'lodash/isEmpty';
import Promise from 'promise';

import adapterUtils from 'app/shared/utils/adapterUtils';
import api from 'app/shared/utils/api';
import AreaActions from 'app/shared/flux/actions/AreaActions';
import BuildingV2 from 'app/shared/models/BuildingV2';
import constants from 'app/shared/constants/ConstantsBundle';
import controller from './controller';
import CrimeScoreCollection from 'app/shared/models/CrimeScoreCollection';
import { DEFAULT } from 'app/shared/models/Filter';
import listingDetailsCache from 'app/shared/cache/listingDetailsCache';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import queryUtils from 'app/shared/utils/queryUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import { adapt_reduxToJava } from 'app/shared/flux/actions/FilterActions/adapters';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('actions/fetchlisting');

// todo: https://jira.hotterpads.com/browse/SITE-3234
// don't chain throws/catches
// return all with generic catch at the end
const adaptedFilter = adapt_reduxToJava({ filter: DEFAULT });
// todo: move functions that don't need to be dispatched into controller
const FetchListingActions = {
  chooseFetchAction({ pathname = '', query = {} }) {
    return function (dispatch) {
      const aliasEncoded = query.aliasEncoded;
      const urlMaloneUnit = routeUtils.getMaloneUnitFromUrl(pathname);
      const shouldShowBuilding = routeUtils.isBuildingUrl(pathname);
      const maloneLotIdEncoded = routeUtils.getMaloneLotIdEncodedFromUrl(pathname);
      const listingTypes = adaptedFilter.listingTypes.split(',').sort();
      const queryString = queryUtils.stringify(query);
      const originalUrlWhenMakingApiCall =
        decodeURI(pathname) || (__BROWSER__ ? decodeURI(window.location.pathname) : '/no-original-url-api-call');

      dispatch(FetchListingActions.resetFetchListingState());

      if (shouldShowBuilding) {
        return dispatch(FetchListingActions.setListingFromCache(pathname)).then(() => {
          return dispatch(
            FetchListingActions.fetchBuilding({
              maloneLotIdEncoded,
              queryString,
              originalUrlWhenMakingApiCall,
            }),
          );
        });
      } else if (aliasEncoded) {
        return dispatch(
          FetchListingActions.fetchListingByAliasEncoded({
            aliasEncoded,
            originalUrlWhenMakingApiCall,
          }),
        );
      } else {
        return dispatch(FetchListingActions.setListingFromCache(pathname)).then(() => {
          return dispatch(
            FetchListingActions.fetchListingByMaloneLotId({
              listingTypes,
              maloneLotIdEncoded,
              urlMaloneUnit,
              queryString,
              originalUrlWhenMakingApiCall,
            }),
          );
        });
      }
    };
  },
  fetchListingByMaloneLotIdNoRedirect({
    maloneLotIdEncoded = '',
    listingTypes,
    urlMaloneUnit = '',
    isBuilding = false,
    originalUrlWhenMakingApiCall = '',
    query = {},
  } = {}) {
    return function (dispatch) {
      const components = constants.FETCH_ALL_COMPONENTS;
      const optional = constants.OPTIONAL_COMPONENTS;
      const timeout = constants.OPTIONAL_COMPONENTS_TIMEOUT;
      const apiArgs = {
        components,
        timeout,
        optional,
        type: isBuilding ? 'building' : 'unit',
        listingTypes: isEmpty(listingTypes) ? null : listingTypes.join(','),
        unit: urlMaloneUnit,
      };
      const queryString = queryUtils.stringify(query);

      return dispatch(api.listing.fetchByMaloneLotIdEncoded(maloneLotIdEncoded, apiArgs))
        .then((apiResponse) => {
          return dispatch(
            FetchListingActions.parseApiResponse({
              apiArgs,
              apiResponse,
              queryString,
              originalUrlWhenMakingApiCall,
            }),
          );
        })
        .then((processedBuilding) => {
          return isBuilding ? processedBuilding : processedBuilding.units[0];
        })
        .catch((err) => {
          logError({
            context: 'FetchListingActions#fetchListingByMaloneLotIdNoRedirect error.',
            errorType: 'FetchListingActionsError',
            error: err,
          });
        });
    };
  },
  fetchListingByMaloneLotId({
    listingTypes,
    maloneLotIdEncoded = '',
    urlMaloneUnit = '',
    queryString = '',
    originalUrlWhenMakingApiCall = '',
    isOnlyCache = false,
  } = {}) {
    return function (dispatch) {
      const components = constants.FETCH_ALL_COMPONENTS;
      const optional = constants.OPTIONAL_COMPONENTS;
      const timeout = constants.OPTIONAL_COMPONENTS_TIMEOUT;
      const showBuilding = false;
      const apiArgs = {
        components,
        timeout,
        optional,
        type: 'unit',
        listingTypes: isEmpty(listingTypes) ? null : listingTypes.join(','),
        unit: urlMaloneUnit || '',
      };

      return dispatch(api.listing.fetchByMaloneLotIdEncoded(maloneLotIdEncoded, apiArgs))
        .then((apiResponse) => {
          return dispatch(
            FetchListingActions.parseApiResponse({
              apiArgs,
              apiResponse,
              queryString,
              originalUrlWhenMakingApiCall,
            }),
          );
        })
        .then((res) => {
          if (res.changeHttpStatus || res.listingNotFound) {
            return res;
          }

          const processedBuilding = res;

          dispatch(FetchListingActions.fetchListingSuccess());
          listingDetailsCache.add(processedBuilding);

          if (isOnlyCache) {
            return {
              processedBuilding,
              listing: processedBuilding,
              originalUrlWhenMakingApiCall,
            };
          } else {
            return dispatch(
              FetchListingActions.validateMaloneUri(
                processedBuilding,
                queryString,
                showBuilding,
                originalUrlWhenMakingApiCall,
              ),
            );
          }
        })
        .then((res = {}) => {
          if (res.changeHttpStatus || res.listingNotFound) {
            return dispatch(FetchListingActions.checkApiRedirectAndStatus(res));
          } else {
            return dispatch(FetchListingActions.insertIntoCache(res));
          }
        })
        .catch((error) => {
          // If the API fails dramatically, 500 and display server error component in the UI
          dispatch(FetchListingActions.fetchListingFail(error));
        });
    };
  },
  fetchListingByAliasEncoded({
    aliasEncoded = '',
    originalUrlWhenMakingApiCall = '',
    shouldSkipRedirect = false,
  } = {}) {
    return function (dispatch) {
      const components = constants.FETCH_ALL_COMPONENTS;
      const optional = constants.OPTIONAL_COMPONENTS;
      const timeout = constants.OPTIONAL_COMPONENTS_TIMEOUT;
      const apiArgs = {
        components,
        timeout,
        optional,
        type: 'unit',
        unit: '',
      };

      return dispatch(api.listing.fetchByAlias(aliasEncoded, apiArgs))
        .then((apiResponse) => {
          return dispatch(
            FetchListingActions.parseApiResponse({
              apiArgs,
              apiResponse,
              originalUrlWhenMakingApiCall,
            }),
          );
        })
        .then((res) => {
          if (res.changeHttpStatus || res.listingNotFound) {
            return res;
          }

          const processedBuilding = res;

          dispatch(FetchListingActions.fetchListingSuccess());

          return dispatch(
            FetchListingActions.validateAliasEncoded(
              processedBuilding,
              aliasEncoded,
              originalUrlWhenMakingApiCall,
              shouldSkipRedirect,
            ),
          );
        })
        .then((res) => {
          if (res.changeHttpStatus || res.listingNotFound) {
            return dispatch(FetchListingActions.checkApiRedirectAndStatus(res));
          } else {
            return dispatch(FetchListingActions.insertIntoCache(res));
          }
        });
    };
  },
  fetchBuilding({
    maloneLotIdEncoded = '',
    queryString = '',
    originalUrlWhenMakingApiCall = '',
    isOnlyCache = false,
  } = {}) {
    return function (dispatch) {
      const components = constants.FETCH_ALL_COMPONENTS;
      const optional = constants.OPTIONAL_COMPONENTS;
      const timeout = constants.OPTIONAL_COMPONENTS_TIMEOUT;
      const showBuilding = true;

      const apiArgs = {
        components,
        timeout,
        optional,
        active: true,
        isBuilding: true,
        type: 'building',
      };

      return dispatch(api.listing.fetchByMaloneLotIdEncoded(maloneLotIdEncoded, apiArgs))
        .then((apiResponse) => {
          return dispatch(
            FetchListingActions.parseApiResponse({
              apiArgs,
              apiResponse,
              queryString,
              originalUrlWhenMakingApiCall,
            }),
          );
        })
        .then((res) => {
          if (res.changeHttpStatus || res.listingNotFound) {
            return res;
          }

          const processedBuilding = res;

          dispatch(FetchListingActions.fetchListingSuccess());

          if (isOnlyCache) {
            return {
              processedBuilding,
              listing: processedBuilding,
              originalUrlWhenMakingApiCall,
            };
          } else {
            return dispatch(
              FetchListingActions.validateMaloneUri(
                processedBuilding,
                queryString,
                showBuilding,
                originalUrlWhenMakingApiCall,
              ),
            );
          }
        })
        .then((res) => {
          if (res.changeHttpStatus || res.listingNotFound) {
            return dispatch(FetchListingActions.checkApiRedirectAndStatus(res));
          } else {
            return dispatch(FetchListingActions.insertIntoCache(res));
          }
        });
    };
  },
  parseApiResponse({ apiArgs = {}, apiResponse = {}, queryString, originalUrlWhenMakingApiCall }) {
    return function (dispatch) {
      if (apiResponse.success && apiResponse.data) {
        return new BuildingV2(apiResponse.data);
      } else {
        return dispatch(
          FetchListingActions.handleEmptyApiDataResponse(apiResponse, {
            queryString,
            isBuilding: apiArgs.isBuilding,
            originalUrlWhenMakingApiCall,
            listingTypes: apiArgs.listingTypes,
            includedUnit: apiArgs.unit,
          }),
        );
      }
    };
  },
  handleEmptyApiDataResponse(apiResponse = {}, { originalUrlWhenMakingApiCall, includedUnit } = {}) {
    return function (dispatch, getState) {
      if (apiResponse.status === 'MALONE_CHANGED' && apiResponse.message) {
        return controller.handleMaloneChange(apiResponse, { originalUrlWhenMakingApiCall });
      } else if (apiResponse.status === 'UNIT_CHANGED' && apiResponse.message) {
        return controller.handleUnitChange(apiResponse, { originalUrlWhenMakingApiCall, includedUnit });
      } else if (apiResponse.status === 'LISTING_NOT_FOUND') {
        logger?.warn(
          {
            expectedHttpResponse: 404,
            originalUrl: originalUrlWhenMakingApiCall,
            serverRendered: !getState().app.clientLoaded,
          },
          'fetchListingError: LISTING_NOT_FOUND',
        );
        return {
          listingNotFound: true,
        };
      } else if (apiResponse.status === 'LOT_ID_NOT_FOUND') {
        return dispatch(FetchListingActions.handleBadMaloneLotId(originalUrlWhenMakingApiCall));
      } else if (apiResponse.status === 'OK' && !apiResponse.data) {
        logger?.warn(
          {
            expectedHttpResponse: 200,
            originalUrl: originalUrlWhenMakingApiCall,
          },
          'fetchListingError: EMPTY_LISTINGS_DATA',
        );
        // probably a blocked listing (only visible to reps)
        return {
          listingNotFound: true,
        };
      } else if (apiResponse.status === 'INVALID_API_CALL' && /Invalid lotIdEncoded value/.test(apiResponse.message)) {
        logger?.warn(
          {
            originalUrl: originalUrlWhenMakingApiCall,
          },
          'fetchListingError: MISSING_LOT_ID',
        );
        return {
          changeHttpStatus: 404,
        };
      } else {
        logger?.warn(
          {
            expectedHttpResponse: 500,
            originalUrl: originalUrlWhenMakingApiCall,
          },
          'fetchListingError: ERROR_LISTINGS_DATA',
        );
        return {
          changeHttpStatus: 500,
        };
      }
    };
  },
  handleBadMaloneLotId(url) {
    const { state, zip } = routeUtils.guessAreaInfoFromUrl({ url });
    const urlEnding = 'apartments-for-rent';

    return function (dispatch) {
      return dispatch(AreaActions.getAreaByResourceId(zip)).then((area) => {
        if (area) {
          logger?.warn(
            {
              message: 'Redirect to area',
              originalUrl: url,
              redirectUrl: `/${zip}/${urlEnding}`,
            },
            'fetchListingError: MALONE_LOT_NOT_FOUND',
          );

          return {
            redirect: true,
            changeHttpStatus: 301,
            to: `/${zip}/${urlEnding}`,
          };
        } else if (state) {
          logger?.warn(
            {
              message: 'Redirect to area',
              originalUrl: url,
              redirectUrl: `/${state}/${urlEnding}`,
            },
            'fetchListingError: MALONE_LOT_NOT_FOUND',
          );

          return {
            redirect: true,
            changeHttpStatus: 301,
            to: `/${state}/${urlEnding}`,
          };
        } else {
          logger?.warn(
            {
              message: 'SHOULD_404',
              originalUrl: url,
            },
            'fetchListingError: MALONE_LOT_NOT_FOUND',
          );
          return {
            changeHttpStatus: 404,
          };
        }
      });
    };
  },
  validateAliasEncoded(processedBuilding = {}, aliasEncoded, originalUrlWhenMakingApiCall, shouldSkipRedirect = false) {
    return function () {
      const matchedUnit = processedBuilding.units.filter((unit) => {
        return unit.aliasEncoded === aliasEncoded;
      });

      if (matchedUnit.length === 0) {
        logger?.warn(
          {
            message: 'SHOULD_404',
            originalUrl: originalUrlWhenMakingApiCall,
          },
          'fetchListingError: ALIAS_NOT_MATCHED',
        );
        return {
          changeHttpStatus: 404,
        };
      }

      if (!shouldSkipRedirect && processedBuilding.uriV2 !== originalUrlWhenMakingApiCall) {
        logger?.warn({
          message: 'Redirect to correct area by aliasEncoded',
          originalUrl: originalUrlWhenMakingApiCall,
        });
        return {
          redirect: true,
          to: processedBuilding.uriV2 + '?aliasEncoded=' + aliasEncoded,
          changeHttpStatus: 301,
        };
      }

      return {
        processedBuilding,
        listing: matchedUnit[0],
        originalUrlWhenMakingApiCall,
      };
    };
  },
  validateMaloneUri(processedBuilding = {}, queryString, shouldShowBuilding, originalUrlWhenMakingApiCall) {
    return function () {
      const matchedUnit = processedBuilding.units.filter((unit) => {
        return unit.uriMalone === originalUrlWhenMakingApiCall || unit.uriBuilding === originalUrlWhenMakingApiCall;
      });
      const noUrlMatched = matchedUnit.length === 0;

      if (noUrlMatched) {
        if (shouldShowBuilding) {
          logger?.warn(
            {
              fetchListingError: 'MALONE_URI_NOT_MATCHED',
              expectedHttpResponse: 301,
              originalUrl: originalUrlWhenMakingApiCall,
              redirectUrl: processedBuilding.units[0].uriBuilding,
            },
            'CHANGED_BUILDING_URI',
          );
          return {
            redirect: true,
            to: processedBuilding.units[0].uriBuilding + queryString,
            changeHttpStatus: 301,
          };
        } else {
          const listingTypes = adaptedFilter.listingTypes.split(',').sort();
          const matchedListingTypeUnits = processedBuilding.units.filter((unit) => {
            return listingTypes.indexOf(unit.listingType) > -1;
          });

          if (matchedListingTypeUnits.length > 0) {
            logger?.warn(
              {
                fetchListingError: 'MALONE_URI_NOT_MATCHED',
                expectedHttpResponse: 301,
                originalUrl: originalUrlWhenMakingApiCall,
                redirectUrl: matchedListingTypeUnits[0].uriMalone,
              },
              'CHANGED_UNIT_URI',
            );
            return {
              redirect: true,
              to: matchedListingTypeUnits[0].uriMalone + queryString,
              changeHttpStatus: 301,
            };
          } else {
            logger?.warn(
              {
                fetchListingError: 'MALONE_URI_NOT_MATCHED',
                expectedHttpResponse: 404,
                originalUrl: originalUrlWhenMakingApiCall,
              },
              'NO_MATCH',
            );
            return {
              changeHttpStatus: 404,
            };
          }
        }
      }

      return {
        processedBuilding,
        listing: shouldShowBuilding ? processedBuilding : matchedUnit[0],
        originalUrlWhenMakingApiCall,
      };
    };
  },
  insertIntoCache(data) {
    return function () {
      const { processedBuilding, listing, originalUrlWhenMakingApiCall } = data;

      // Previously, we stored all of this data in Redux,
      // which triggers re-renders. We don't need to do that.
      // only need to cache on the client side. we make the same
      // api call on the client and server.
      if (__BROWSER__ && processedBuilding.maloneLotIdEncoded) {
        listingDetailsCache.add(processedBuilding);
      }

      return {
        listing,
        originalUrlWhenMakingApiCall,
      };
    };
  },
  checkApiRedirectAndStatus(errorResponse = {}) {
    return function (dispatch, getState) {
      if (errorResponse.redirect) {
        if (__BROWSER__) {
          window.router.redirectTo(errorResponse.to);
        }

        return {
          redirect: true,
          to: errorResponse.to,
          changeHttpStatus: errorResponse.changeHttpStatus,
        };
      } else if (errorResponse.listingNotFound) {
        const store = getState();
        const recentSearchResourceId =
          !isEmpty(store.user.search && store.user.search.recent) && store.user.search.recent.areaInfo.resourceId;
        dispatch(FetchListingActions.fetchListingFail(constants.LISTING_NOT_FOUND));
        dispatch(ListingEngineActions.setCurrentListing(null));
        dispatch(AreaActions.getAndSetAreaByResourceId(recentSearchResourceId || 'new-york-ny'));
        return {
          changeHttpStatus: 410,
        };
      } else if (errorResponse.changeHttpStatus === 404) {
        dispatch(FetchListingActions.fetchListingFail(constants.MALONE_LOT_ID_NOT_FOUND));
        dispatch(ListingEngineActions.setCurrentListing(null));

        return {
          changeHttpStatus: 404,
        };
      } else {
        logError({
          error: errorResponse,
          errorType: 'FetchListingActionsError',
          context: { fetchListingError: 'unknownResponse' },
        });
        dispatch(FetchListingActions.fetchListingFail(constants.INTERNAL_SERVER_ERROR));
        dispatch(ListingEngineActions.setCurrentListing(null));

        return {
          changeHttpStatus: errorResponse.changeHttpStatus || 500,
        };
      }
    };
  },
  setListingFromCache(pathname) {
    return function (dispatch) {
      const shouldShowBuilding = routeUtils.isBuildingUrl(pathname);
      const maloneLotIdEncoded = routeUtils.getMaloneLotIdEncodedFromUrl(pathname);
      const selectedBuilding = listingDetailsCache.get(maloneLotIdEncoded);

      let cachedListing;

      if (selectedBuilding && shouldShowBuilding) {
        cachedListing = selectedBuilding;
      } else if (selectedBuilding) {
        selectedBuilding.units.forEach((unit) => {
          if (unit.uriMalone === pathname) {
            cachedListing = unit;
          }
        });
      }

      if (cachedListing) {
        return dispatch(ListingEngineActions.setCurrentListing(cachedListing));
      } else {
        return Promise.resolve(false);
      }
    };
  },
  resetSimilarListings() {
    return function (dispatch) {
      return dispatch({
        type: constants.FETCH_SIMILAR_LISTINGS_RESET,
      });
    };
  },
  fetchSimilarListings({ maloneLotIdEncoded, unit, listingTypes, limit = 10 }) {
    return function (dispatch) {
      if (!maloneLotIdEncoded) {
        return Promise.resolve();
      }

      dispatch(FetchListingActions.resetSimilarListings());

      return dispatch(
        api.listing.similarByMaloneLotIdEncoded({
          maloneLotIdEncoded,
          unit,
          listingTypes,
          limit,
        }),
      ).then((apiResponse) => {
        const arrayOfBuildings = apiResponse.data;
        const similarListings = adapterUtils.apiLimitedBuildingArrayToSummaryArray(arrayOfBuildings);

        return dispatch({
          type: constants.FETCH_SIMILAR_LISTINGS_SUCCESS,
          payload: similarListings,
        });
      });
    };
  },
  fetchSimilarListingsByCoords(params) {
    return (dispatch) => {
      dispatch(FetchListingActions.resetSimilarListings());
      const { area, filter, limit } = params;

      const apiParams = adapt_reduxToJava({
        filter,
        area,
        limit,
        channels: 'HotPadsMainPremium',
      });

      return dispatch(api.listing.fetchByCoords(apiParams)).then((res) => {
        const {
          data: { buildings },
        } = res;
        const similarListings = adapterUtils.apiLimitedBuildingArrayToSummaryArray(buildings).slice(0, 20);

        return dispatch({
          type: constants.FETCH_SIMILAR_LISTINGS_SUCCESS,
          payload: similarListings,
        });
      });
    };
  },
  fetchProviderListingsPortfolio(aliasEncoded) {
    return (dispatch) => {
      dispatch({
        type: constants.FETCH_LISTINGS_PORTFOLIO_RESET,
      });

      // Get the MF provider's portfolio of listings
      return dispatch(api.listing.listingsPortfolio(aliasEncoded))
        .then((portfolioResults) => {
          if (portfolioResults.data) {
            const arrayOfListings = portfolioResults.data.buildings;
            const listingsPortfolio = adapterUtils.apiLimitedBuildingArrayToSummaryArray(arrayOfListings);

            return dispatch({
              type: constants.FETCH_LISTINGS_PORTFOLIO_SUCCESS,
              payload: listingsPortfolio,
            });
          }
        })
        .catch((err) => {
          logError({ context: 'fetchProviderListingsPortfolio', error: err, errorType: 'FetchListingActionsError' });
          return false;
        });
    };
  },
  resetRelatedRentals() {
    return function (dispatch) {
      return dispatch({
        type: constants.FETCH_RELATED_RENTALS_RESET,
      });
    };
  },
  fetchRelatedRentals({ maloneLotIdEncoded, unit }) {
    return function (dispatch) {
      if (!maloneLotIdEncoded) {
        return Promise.resolve();
      }

      dispatch(FetchListingActions.resetRelatedRentals());

      return dispatch(
        api.listing.similarByMaloneLotIdEncoded({
          maloneLotIdEncoded,
          unit,
          listingTypes: 'rental',
        }),
      ).then((apiResponse) => {
        const arrayOfBuildings = apiResponse.data;
        const relatedRentals = adapterUtils.apiLimitedBuildingArrayToSummaryArray(arrayOfBuildings);

        return dispatch({
          type: constants.FETCH_RELATED_RENTALS_SUCCESS,
          payload: relatedRentals,
        });
      });
    };
  },
  fetchListingDetails(listing = {}) {
    return function (dispatch) {
      const additionalComponents = [];
      const additionalOptionalComponents = [];

      // Why not fetch all the same components as OPTIONAL_COMPONENTS: 'popularity,listedby,zestimate'?
      if (!listing.popularity) {
        additionalOptionalComponents.push('popularity');
      }
      if (!listing.listedBy) {
        additionalComponents.push('listedBy');
      }

      if (additionalComponents.length) {
        const apiArgs = {
          components: additionalComponents.join(','),
          optional: additionalOptionalComponents.join(','),
        };

        return dispatch(api.listing.fetchByAlias(listing.aliasEncoded, apiArgs))
          .then((apiResponse) => {
            const listingUpdates = apiResponse.data && apiResponse.data.listings[0];
            if (apiResponse.success && listingUpdates) {
              dispatch({
                type: constants.FETCH_LISTING_UPDATE,
                payload: {
                  listingUpdates,
                },
              });
            }
          })
          .catch((err) => {
            logError({
              error: err,
              errorType: 'FetchListingActionsError',
              context: 'fetchListingDetails',
            });
            return false;
          });
      } else {
        return Promise.resolve();
      }
    };
  },
  resetFetchListingState(listingIsLoading = false) {
    return function (dispatch) {
      return dispatch({
        type: constants.RESET_FETCH_LISTING_STATE,
        payload: { listingIsLoading },
      });
    };
  },
  fetchListingFail(error = 'Failed to fetch listing.') {
    return function (dispatch) {
      return dispatch({
        type: constants.FETCH_LISTING_FAIL,
        payload: { error },
      });
    };
  },
  fetchListingSuccess() {
    return function (dispatch) {
      return dispatch({
        type: constants.FETCH_LISTING_SUCCESS,
      });
    };
  },
  fetchListingCrimeScores(maloneLotIdEncoded) {
    return (dispatch) => {
      return dispatch(api.listing.crimeByMaloneLotId(maloneLotIdEncoded)).then((result) => {
        const { data } = result;
        return dispatch({
          type: constants.LOAD_LISTING_CRIME_SCORES,
          payload: { crimeScores: new CrimeScoreCollection(data) },
        });
      });
    };
  },
  fetchListingPermissions(listingAlias) {
    return (dispatch, getState) => {
      const store = getState();
      const userEmail = store.user.info.email;
      const userToken = store.user.userToken;
      if (userEmail === null) {
        return;
      }
      return dispatch(api.review.hasAuthToRespond(listingAlias, userEmail, userToken)).then((res) => {
        const { data } = res;

        if (!res.success || !data.success) {
          return;
        }

        return dispatch({
          type: constants.SET_USER_REVIEW_REPLY_PERMISSION_BOOL,
          payload: data.allowedToRespond,
        });
      });
    };
  },
};

export default FetchListingActions;
