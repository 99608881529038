// @ts-nocheck
/* eslint-enable */
import queryUtils from 'app/shared/utils/queryUtils';
import { getGlobalLogger } from '@zg-rentals/logger-base';
import { logError } from '@zg-rentals/log-error';

const logger = getGlobalLogger('actions/fetchlisting/controller');

export default {
  handleMaloneChange(apiResponse = {}, { originalUrlWhenMakingApiCall }) {
    const redirectMaloneLotId = apiResponse.message;
    const urlArray = originalUrlWhenMakingApiCall.split('/');
    const addressPieceUrl = urlArray[1];
    const addressPieceArray = addressPieceUrl.split('-');
    addressPieceArray[addressPieceArray.length - 1] = redirectMaloneLotId;
    const newAddressPieceUrl = addressPieceArray.join('-');
    urlArray[1] = newAddressPieceUrl;
    const redirectString = urlArray.join('/');

    logger?.warn(
      {
        expectedHttpResponse: 301,
        originalUrl: originalUrlWhenMakingApiCall,
        redirectUrl: redirectString,
      },
      'MALONE_CHANGED',
    );

    if (redirectString === originalUrlWhenMakingApiCall) {
      logError({
        error: 'INFINITE_REDIRECT_MALONE_CHANGED',
        errorType: 'FetchListingActionsError',
        context: { originalUrl: originalUrlWhenMakingApiCall, redirectUrl: redirectString, expectedHttpResponse: 301 },
      });
    }
    return {
      redirect: true,
      to: redirectString,
      changeHttpStatus: 301,
    };
  },
  handleUnitChange(apiResponse = {}, { originalUrlWhenMakingApiCall, includedUnit }) {
    const urlArray = originalUrlWhenMakingApiCall.split('/');
    const originalUnit = includedUnit;
    const redirectUnit = queryUtils.parse(apiResponse.message).unit;
    const oldArrayHasUnit = urlArray.length === 4 && originalUnit;

    if (oldArrayHasUnit) {
      urlArray[2] = redirectUnit; // replace unit with new unit
    } else {
      urlArray.splice(2, 0, redirectUnit);
    }

    const redirectString = urlArray.join('/');

    logger?.warn(
      {
        expectedHttpResponse: 301,
        originalUrl: originalUrlWhenMakingApiCall,
        redirectUrl: redirectString,
      },
      'fetchListingError: UNIT_CHANGED',
    );
    if (redirectString === originalUrlWhenMakingApiCall) {
      logError({
        error: 'INFINITE_REDIRECT_UNIT_CHANGED',
        errorType: 'FetchListingActionsError',
        context: { originalUrl: originalUrlWhenMakingApiCall, redirectUrl: redirectString, expectedHttpResponse: 301 },
      });
    }
    return {
      redirect: true,
      to: redirectString,
      changeHttpStatus: 301,
    };
  },
};
